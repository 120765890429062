import React, { useCallback } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import moment from 'moment';

function LogsTable(props) {
  window.moment = moment
  const [isLoading, setIsLoading] = useState(false)
  const [gridRef, setGridRef] = useState(null)
  const [transactions, setTransactions] = useState([])
  const [selected, setSelected] = useState({})

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected)
    console.log(selected)
  }, []);

  const dataMap =
    gridRef && gridRef.current && gridRef.current.dataMap
      ? gridRef.current.dataMap
      : null;

  const fetchAllLogs = async () => {
    try {
      setIsLoading(true);
      const data = await AxiosPost('fetch_all_logs.php');
      console.log(data)
      if (data.success) {
        setTransactions(data.logs)
      }
      else {
        pushNotify("error", "Error", data.error)
      }
    } catch {
      pushNotify("error", "Error", "Server Error!")
    } finally {
      setIsLoading(false)
    }
  }

  const fetchLogsLinkedTransactions = async () => {
    try {
      setIsLoading(true);
      const data = await AxiosPost('fetch_logslinkedtransactions.php',{id:props.index});
      console.log(data)
      if (data.success) {
        setTransactions(data.transactions)
      }
      else {
        pushNotify("error", "Error", data.error)
      }
    } catch {
      pushNotify("error", "Error", "Server Error!")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    props.renderIds?fetchLogsLinkedTransactions():fetchAllLogs()
  }, [])

  const gridStyle = { height: 'calc(100vh - 16.5rem)', marginTop: 10 };


  const filterValue = [
    { name: 'sl', operator: 'gte', type: 'number', value: '' },
    { name: 'shoot_date', operator: 'before', type: 'date', value: '' },
    { name: 'vendor', operator: 'startsWith', type: 'string', value: '' },
    { name: 'remarks', operator: 'startsWith', type: 'string', value: '' },
    { name: 'LogType', operator: 'startsWith', type: 'string', value: '' },
  ];

  const columns = [
    { name: 'sl', header: 'Sl', minWidth: 150 },
    { name: 'shoot_date', defaultFlex: 2, header: 'Shoot Date', minWidth: 150, filterEditor: DateFilter, },
    { name: 'vendor', defaultFlex: 2, header: 'Vendor', minWidth: 150 },
    { name: 'remarks', defaultFlex: 2, header: 'Remarks', minWidth: 150 },
    { name: 'LogType', defaultFlex: 2, header: 'Log Type',  minWidth: 150 },
  ];



  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    const header = columns.map((c) => c.name).join(',');
    const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(','));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);
  };

  const downloadBlob = (blob, fileName = 'grid-data.csv') => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const refresh = async () => {
    await fetchAllLogs();
  }


  const toArray = (selected, dataMap) => {
    const keysObj = selected === true ? dataMap : selected;
    return Object.keys(keysObj).map(key => Number(key))
  }


  useEffect(() => { onChangeHandler() }, [selected])

  const onChangeHandler = () => {
    props.setFormData(props.formData.map(obj=>{ return {...obj,linked_logs:Object.values(selected)}}))
  }

  return (
    <div>

      <>
        <Button style={{ "background-color": "var(--primary)", "color": "white" }} onClick={refresh}>
          Sync Data
        </Button>
        <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={exportCSV}>
          Export CSV
        </Button>
        <ReactDataGrid
          idProperty="sl"
          checkboxColumn={!props.renderIds?true:false}
          selected={selected}
          onSelectionChange={onSelectionChange}
          style={gridStyle}
          columns={columns}
          pagination="local"
          dataSource={transactions}
          defaultLimit={25}
          defaultFilterValue={filterValue}
          handle={setGridRef}
          loading={isLoading || isLoading ? true : false}
        />
      </>
    </div>
  )
}

export default LogsTable